<template>
  <div id="header">
  <!-- Header -->

  <!-- INTRODUCTION -->
  <div v-if="dashboardMode == 'Introduction'" class="header bg-gradient-primary pb-6 pt-2 pt-md-5">
    <!-- <video class="background-video hidden-xs" autoplay="" preload="" muted="" loop="" poster="/cclbc2018/img/westfield_home.jpg">
      <source src="http://orbitist.s3.amazonaws.com/2017/cclbc/ambient_video/cclbc_ambient1.mp4" type="video/mp4">
    </video> -->
    <div class="container-fluid">
      <div class="header-body mb-5">
        <p class="lead text-light">Tackling the issues of blight and its negative impacts requires a multi-pronged approach and the dedication of resources from the public, private and non-profit sectors. It also requires the engagement of our residents.</p>
      </div>
    </div>
  </div>
  <!-- END INTRODUCTION -->

  <!-- MISSION -->
  <div v-if="dashboardMode == 'Mission'" class="header bg-gradient-primary pb-6 pt-2 pt-md-5">
    <div class="container-fluid">
      <div class="header-body mb-5">
        <p class="lead text-light">We minimize the negative impacts that substandard properties and structures have on communities,
thereby stabilizing neighborhoods and main streets alike.</p>
      </div>
    </div>
  </div>
  <!-- END MISSION -->

  <!-- PROBLEM -->
  <div v-if="dashboardMode == 'Problem'" class="header bg-gradient-primary pb-6 pt-5 pt-md-5">
    <div class="container-fluid">
      <div class="header-body mb-5">
        <p class="lead text-light">Vacancy & Blight in Chautauqua County</p>
      </div>
    </div>
  </div>
  <!-- END PROBLEM -->

  <!-- WORK -->
  <div v-if="dashboardMode == 'Work'" class="header bg-gradient-primary pb-7 pt-5 pt-md-5">
    <div class="container-fluid">
      <div class="header-body">
        <p class="lead text-light">Highlights of 2019 Activities</p>
      </div>
    </div>
  </div>
  <!-- END WORK -->

  <!-- OUTCOMES -->
  <div v-if="dashboardMode == 'Outcomes'" class="header bg-gradient-primary pb-7 pt-5 pt-md-5">
    <div class="container-fluid">
      <div class="header-body">
        <p class="lead text-light">Results and Financial Information from 2018</p>
      </div>
    </div>
  </div>
  <!-- END OUTCOMES -->

  <!-- TEAM -->
  <div v-if="dashboardMode == 'Team'" class="header bg-gradient-primary pb-5 pt-5 pt-md-8">
    <div class="container-fluid">
      <div class="header-body">

      </div>
    </div>
  </div>
  <!-- END TEAM -->

  </div><!-- /#header -->
</template>

<script>
export default {
  props: ['dashboardMode']
}
</script>
