<template>
  <div id="introduction">
        <div class="card bg-gradient-default shadow">
          <div class="card-header bg-transparent">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-light ls-1 mb-1">Mission</h6>
                <h2 class="text-white mb-0">Overview</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <iframe width="100%" height="515" src="https://www.youtube.com/embed/0cxsjEBQB80" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>

    <div class="row mt-5">

      <div class="col-xl-8 mb-5 mb-xl-0">

        <div class="card shadow">
          <div class="card-header bg-transparent">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0">Mission Statement</h3>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>Control and manage strategically selected dilapidated and abandoned residential and commercial properties acquired through the County tax foreclosure process, bank foreclosures and/or donations, and facilitate solutions aimed at stabilizing neighborhoods, encouraging private investment, and improving the quality of life throughout Chautauqua County.</p>
          </div>
        </div>

      </div>

      <div class="col-xl-4">
        <div class="card shadow">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0">History</h3>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p>The Chautauqua County Land Bank Corporation (CCLBC) was designated as one of the first five land banks in NYS, by authority of the New York State Land Bank Act, in 2012.</p>
          </div>
        </div>
      </div>

    </div><!-- /.row -->
    <div class="row pl-3 pr-3">
      <div class="card shadow mt-4">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Engaging Stakeholders</h3>
            </div>
          </div>
        </div><!-- /.card-header -->
        <div class="card-body">
          <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/cclbc-annual-report-2018.appspot.com/o/images%2FPicture-EngagingStakeholdersVenn.png?alt=media&token=bf593228-b07d-420f-ba9b-bc504ec7fa8c" />
        </div>
      </div>
    </div><!-- /.row -->

    <!-- <div class="card bg-gradient-default shadow">
      <div class="card-body">
        <AllocationsPie />
      </div>
    </div> -->
  </div>
</template>

<script>
// import AllocationsPie from './AllocationsPie.vue'

export default {
  components: {
    // AllocationsPie
  }
}
</script>
