<template>
  <div id="introduction">
    <div class="row">
      <div class="col-12 mb-5">
        <div class="card bg-gradient-default shadow">
          <div class="card-header bg-transparent">
            <div class="row align-items-center">
              <div class="col">
                <h2 class="text-white mb-0">Major Accomplishments this Year</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              v-for="(accomplishment, i) in accomplishments"
              v-bind:key="i"
              class="grid-item-container"
            >
              <div @click="showAccomplishmentDetail(accomplishment)" class="grid-item clickable">
                <div v-html="accomplishment.icon" class="grid-icon">
                </div><!-- /.grid-icon -->
                <div class="grid-title">
                  {{ accomplishment.title }}
                </div><!-- /.grid-title -->
              </div><!-- /.grid-item -->
            </div><!-- /.grid-item-container -->
          </div>
        </div>
      </div>
    </div>

    <!-- ACCOMPLISHMENT DETAIL MODAL -->
    <transition name="fade">
      <div v-if="showAccomplishmentDetailModal == true" class="grid-modal-container">
        <div class="grid-modal">
          <a @click="closeAccomplishmentModal()" class="close clickable"><i class="far fa-times"></i></a>
          <div v-html="accomplishmentDetailed.icon" class="grid-modal-icon">
          </div><!-- /.grid-modal-icon -->
          <div class="grid-modal-title">
            {{ accomplishmentDetailed.title }}
          </div><!-- /.grid-modal-title -->
          <div class="grid-modal-description">
            {{ accomplishmentDetailed.description }}
          </div><!-- grid-modal-description -->
          <img v-if="accomplishmentDetailed.img" :src="accomplishmentDetailed.img" class="w-100" />
          <div v-if="accomplishmentDetailed.map" class="modal-map">
            <iframe width="100%" height="450px" :src="accomplishmentDetailed.mapUrl" />
          </div>
        </div><!-- /.grid-modal-container -->
      </div><!-- /.grid-modal-container -->
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      accomplishments: [
        {
          icon: '<i class="fas fa-home"></i>',
          title: 'Acquisitions',
          description: 'Only one residential property was acquired in 2020, due to the moratorium on evictions and foreclosure proceedings constricting availability of vacant housing. Three additional parcels were acquired for demolition or post demolition for transfer to new adjacent owners.',
          map: true,
          mapUrl: 'https://airtable.com/embed/shr5oUcGfb09tVAF8?backgroundColor=purpleLight&viewControls=on',
          img: '/static/misc/acquisitions.jpg'
        },
        {
          icon: '<i class="fas fa-handshake"></i>',
          title: 'Dispositions',
          description: '18 Properties were sold. Of those, 15 were residential properties and three were vacant lots, representing $514,500 in assessment value returned to the county tax rolls, and $6,425,000 in private reinvestment, with $625,000 in residential rehabilitation, and an additional $5,800,000 in commercial/emergency housing projects.',
          map: true,
          mapUrl: 'https://airtable.com/embed/shrF5hcfbmREYO53Y?backgroundColor=purpleLight&viewControls=on',
          img: '/static/misc/dispositions.jpg'
        },
        {
          icon: '<i class="fas fa-exclamation-triangle"></i>',
          title: 'Demolitions',
          description: '$422,556 of our grant proceeds were spent on demolition across the county in 2020. Despite the “shelter in place” order, and multiple work site shut-downs due to COVID outbreaks, the Land Bank with the assistance of its partners Jamestown Urban Renewal Agency (JURA) and the City of Dunkirk, moved numerous derelict residential and commercial properties through the project pipeline to rid our cities and rural communities of blighted and unsafe properties.'
        },
        // {
        //   icon: '<i class="fas fa-user-friends"></i>',
        //   title: 'NEIGHBORS 4 NEIGHBORHOODS Program',
        //   description: '4 Properties, 2 in Dunkirk and 2 in Jamestown, renovated to high quality standards, creating 7 affordable rentals that are beautiful, lead and asbestos-free with responsible land lords committed to keeping them up to standard and at affordable rent levels for the next 20 years. This program represents a state (non-tax dollars) investment of over $450,000 in affordable housing in our communities.'
        // },
        {
          icon: '<i class="fas fa-history"></i>',
          title: 'OPERATIONS',
          description: `Grant Revenue bolstered our operations in 2020 with the addition of $51,000 in COVID related assistance, which reduced the burden of our administrative expenses during a year when property acquisitions were curtailed, and sales negatively impacted by the pandemic. Our Community Revitalization Initiative grant disbursements, provided the majority of our program funding, with disbursements of $639,443 in 2020. The balance of this funding will carry-through to the end of 2021. Real estate sales revenues bolstered our operations at just under $228,000; all but one of the properties sold was inventory acquired prior to 2020. By making the decision to invest  a sum of $500,000 of our cash assets in a CD, the land bank generated over $7,000 in interest revenue.
Expenses directly related to property acquisition and holding costs, aka “Cost of Goods Sold” continues to be our largest expense, at 71% of our total expenses in 2020. Salaries (including payroll taxes) and Benefits, account for less than 19% of our budget, with overhead accounting for the balance, or 10%.
In examining the detail of Cost of Goods Sold, excluding the cost of demolition, the Land Bank’s largest expense in 2020 was real estate taxes. At just under $60,000, our annual tax payments assist the county with the revenue lost when they “make whole” the municipalities for tax delinquencies that resulted in foreclosures. Although the land bank is tax exempt upon acquisition, the organization made an agreement to pay the current year’s taxes on any property acquired from the county’s tax foreclosure auction. Construction draws at over $55,000 represent grant-funded rehab investments made to complete the Dunkirk Neighbor for Neighborhoods project, and a lead abatement project in Brocton. Acquisition costs account for approximately $47,000, which reflects the purchase of a bank-owned property (that the land Bank was able to re-sell at a healthy profit, to assist with our sustainability challenge) and Property Clean-out and Maintenance account for approximately $41,000 in annual expense.
As we track our revenues and expenses, we continually assess our operations and procedures, and identify opportunities to enhance our sustainability through controlling costs and improving profits.`
        }
        // {
        //   icon: '<i class="fas fa-laptop-code"></i>',
        //   title: 'New Website',
        //   description: 'Upgraded our web site to provide more program visibility and transparency to our program and policies, and increasing our on line traffic. In 2018 the website served 31,545 pages to 4,423 unique users. 85.6% of those users were new to the site. By far the most visited area of the site was our properties listings with 23.4% of all pageviews hapenning within the properties listings database.'
        // },
        // {
        //   icon: '<i class="fas fa-dollar-sign"></i>',
        //   title: '$2 Million Dollar Grant',
        //   description: 'We secured a $2 million grant from the NYS Attorney General’s office, administered through Enterprise Community Partners, to support our program operations and expansion into 2020.'
        // }
      ],
      showAccomplishmentDetailModal: false,
      accomplishmentDetailed: {}
    }
  },
  methods: {
    closeAccomplishmentModal () {
      this.showAccomplishmentDetailModal = false
    },
    showAccomplishmentDetail (accomplishment) {
      this.accomplishmentDetailed = accomplishment
      this.showAccomplishmentDetailModal = true
    }
  }
}
</script>

<style scoped>
.grid-item-container {
  display: inline-block;
  width: 50%;
  padding: 5px 10px;
}

.grid-item {
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 10px 30px;
  transition: all 0.2s ease-in-out;
}

.grid-item:hover {
  transform: scale(1.05);
}

.grid-icon {
  font-size: 50px;
}

.grid-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 1;
  padding: 30px;
  overflow-y: scroll;
}

.grid-modal {
  position: absolute;
  top: 20px;
  width: calc(100% - 60px);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.grid-modal-icon {
  font-size: 50px;
}

.grid-modal-title {
  font-size: 32px;
  font-weight: lighter;
  margin-bottom: 10px;
}

.grid-modal-description {
  margin: 0 auto 20px;
  max-width: 500px;
}
</style>
