<template>
  <div id="dashboard">
    <!-- Sidenav -->
    <Sidenav v-on:changeDashboardMode="changeDashboardMode($event)"></Sidenav>
      <!-- Main content -->
      <div class="main-content">
        <Navbar :dashboardMode="dashboardMode"></Navbar>
        <Header :dashboardMode="dashboardMode"></Header>
        <div class="container-fluid mt--7">
          <Introduction v-if="dashboardMode == 'Introduction'"></Introduction>
          <Mission v-if="dashboardMode == 'Mission'"></Mission>
          <Problem v-if="dashboardMode == 'Problem'"></Problem>
          <Work v-if="dashboardMode == 'Work'"></Work>
          <Outcomes v-if="dashboardMode == 'Outcomes'"></Outcomes>
          <Team v-if="dashboardMode == 'Team'"></Team>
          <Footer></Footer>
        </div><!-- /.container -->
      </div><!-- /.main-content -->
  </div><!-- /#dashboard -->
</template>

<script>
import Sidenav from './Sidenav.vue'
import Navbar from './Navbar.vue'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Introduction from './Introduction.vue'
import Mission from './Mission.vue'
import Problem from './Problem.vue'
import Work from './Work.vue'
import Outcomes from './Outcomes.vue'
import Team from './Team.vue'

export default {
  data () {
    return {
      dashboardMode: 'Introduction'
    }
  },
  components: { Sidenav, Navbar, Header, Introduction, Footer, Mission, Problem, Work, Outcomes, Team },
  methods: {
    changeDashboardMode (mode) {
      this.dashboardMode = mode
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style>
.clickable {
  cursor: pointer;
}
.header {
  overflow: hidden;
}

.background-video {
  width: 100%;
  position: absolute;
  top: 0px;
}
.sidenav-logo {
  width: 100%;
}
</style>
