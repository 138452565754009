<template>
  <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
      <!-- Mobile Menu Controls -->
      <button
        class="mobile-nav-btn btn-lg btn-primary"
        v-if="showMobileNav === false"
        @click="showMobileNav = true"
      >
         <i class="fas fa-compass"></i> Menu
      </button>
      <button
        class="mobile-nav-btn btn-lg btn-primary"
        v-if="showMobileNav === true"
        @click="showMobileNav = false"
      >
         <i class="fas fa-times-circle"></i> Close Menu
      </button>
      <div
        class="mobile-menu-background"
        v-if="showMobileNav === true"
      ></div>
      <div
        class="mobile-menu"
        v-if="showMobileNav === true"
      >
        <i
          class="fas fa-times-circle text-primary clickable"
          @click="showMobileNav = false"
        ></i>
        <h2>Menu</h2>
        <ul class="list-group">
          <li class="list-group-item" @click="changeDashboardMode('Introduction')">
            <div class="nav-link clickable">
              <i class="fal fa-home"></i> Introduction
            </div>
          </li>
          <li class="list-group-item" @click="changeDashboardMode('Mission')">
            <div class="nav-link clickable">
              <i class="fal fa-handshake-alt"></i> Mission
            </div>
          </li>
          <li class="list-group-item" @click="changeDashboardMode('Problem')">
            <div class="nav-link clickable">
              <i class="fal fa-exclamation-circle"></i> Problem
            </div>
          </li>
          <li class="list-group-item" @click="changeDashboardMode('Work')">
            <div class="nav-link clickable">
              <i class="fal fa-briefcase"></i> 2020 Work
            </div>
          </li>
          <li class="list-group-item" @click="changeDashboardMode('Outcomes')">
            <div class="nav-link clickable">
              <i class="fal fa-chart-line"></i> Outcomes
            </div>
          </li>
          <li class="list-group-item" @click="changeDashboardMode('Team')">
            <div class="nav-link clickable">
              <i class="fal fa-users"></i> Team
            </div>
          </li>
        </ul>
      </div><!-- /.mobile-menu -->
      <!-- Brand -->
      <img @click="changeDashboardMode('Introduction')" src="http://labs.orbitist.com/cclbc2018/img/cclbc_logo.png" class="sidenav-logo clickable" alt="Land Bank Logo">
      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a href="./index.html">
                <img src="../assets/img/brand/blue.png">
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#sidenav-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>

        <!-- Navigation -->
        <ul class="navbar-nav">
          <li class="nav-item" @click="changeDashboardMode('Introduction')">
            <div class="nav-link clickable">
              <i class="fal fa-home"></i> Introduction
            </div>
          </li>
          <li class="nav-item" @click="changeDashboardMode('Mission')">
            <div class="nav-link clickable">
              <i class="fal fa-handshake-alt"></i> Mission
            </div>
          </li>
          <li class="nav-item" @click="changeDashboardMode('Problem')">
            <div class="nav-link clickable">
              <i class="fal fa-exclamation-circle"></i> Problem
            </div>
          </li>
          <li class="nav-item" @click="changeDashboardMode('Work')">
            <div class="nav-link clickable">
              <i class="fal fa-briefcase"></i> 2020 Work
            </div>
          </li>
          <li class="nav-item" @click="changeDashboardMode('Outcomes')">
            <div class="nav-link clickable">
              <i class="fal fa-chart-line"></i> Outcomes
            </div>
          </li>
          <li class="nav-item" @click="changeDashboardMode('Team')">
            <div class="nav-link clickable">
              <i class="fal fa-users"></i> Team
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      showMobileNav: false
    }
  },
  methods: {
    changeDashboardMode (mode) {
      this.showMobileNav = false
      this.$emit('changeDashboardMode', mode)
    }
  }
}
</script>

<style>
.mobile-nav-btn {
  display: none;
}

.mobile-menu, .mobile-menu-background {
  display: none;
}

@media (max-width: 767px) {
  .mobile-nav-btn {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10;
  }

  .mobile-menu {
    display: block;
    position: fixed;
    top: 30px;
    left: 20px;
    width: calc(100% - 40px);
    z-index: 11;
    background-color: #eee;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.3);
  }

  .mobile-menu .fa-times-circle {
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 32px;
  }

  .mobile-menu-background {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(0,0,0,0.8);
  }
}
</style>
