<template>
  <!-- Top navbar -->
  <nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
    <div class="container-fluid">
      <!-- Brand -->
      <div class="h2 mb-0 text-white text-uppercase d-none d-lg-inline-block mt-2">{{ dashboardMode }}</div>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['dashboardMode'],
  data () {
    return {
    }
  }
}
</script>
