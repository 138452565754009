<template>
  <div id="introduction">
    <!-- <div class="card bg-gradient-default shadow">
      <div class="card-body">
        <FinancialsPie />
      </div>
    </div> -->

    <div class="row mt-4">
      <div class="col-md-12">
        <div class="card bg-gradient-default shadow">
          <div class="card-body">
            <img class="img-fluid" src="/static/financials/position.jpg">
          </div>
        </div>
      </div><!-- /.col-md-6 -->
      <div class="col-md-12 mt-4">
        <div class="card bg-gradient-default shadow">
          <div class="card-body">
            <img class="img-fluid" src="/static/financials/activities.jpg">
          </div>
        </div>
      </div><!-- col-md-6 -->
      <div class="col-md-12 mt-4">
        <div class="card bg-gradient-default shadow">
          <div class="card-body">
            <img class="img-fluid" src="/static/financials/expenses.jpg">
          </div>
        </div>
      </div><!-- col-md-6 -->
    </div><!-- /.row -->

  </div>
</template>

<script>
// import FinancialsPie from './FinancialsPie'

export default {
  components: {
    // FinancialsPie
  }
}
</script>
