<template>
  <div id="introduction">
    <div class="card shadow mb-4">
      <div class="card-header bg-transparent">
        <div class="row align-items-center">
          <div class="col">
            <h6 class="text-uppercase text-muted ls-1 mb-1">Tackling Blight</h6>
            <h2 class="mb-0">Featured Rehab</h2>
          </div>
        </div>
      </div>
      <div class="card-body">
        <image-compare :before="afterImage" :after="beforeImage" class="mb-4">
          <i class="fa fa-angle-left" aria-hidden="true" slot="icon-left"></i>
          <i class="fa fa-angle-right" aria-hidden="true" slot="icon-right"></i>
        </image-compare>
<p>This Jamestown home was acquired as a tax foreclosure in 2018 by the Land Bank. It had been vacant for several years.
The purchasers completed an upscale renovation with a modern open floor plan and upgraded appointments, while respecting and restoring the period details of the home.
The home was recently sold to first-time homebuyers who will occupy the residence with their young family.</p>
      </div><!-- /.card-body -->
    </div><!-- /.card -->

<!-- STATS SECTION -->
<!--
<div class="row mb-4">
  <div class="col-md-3 pr-0">
    <div class="card card-stats mt-2 mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Jamestown Demos</h5>
            <span class="h4 mb-0">Goal: 30</span><br>
            <span class="h4 mb-0">Demolished: 59</span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-success text-white rounded-circle shadow">
              <i class="fas fa-chart-bar"></i>
            </div>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 197%</span>
          <span class="text-nowrap">Achievement Rate</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-3 pr-0">
    <div class="card card-stats mt-2 mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Dunkirk Demos</h5>
            <span class="h4 font-weight-bold mb-0">Goal: 15</span><br>
            <span class="h4 font-weight-bold mb-0">Demolished: 10</span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
              <i class="fas fa-chart-bar"></i>
            </div>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> 67%</span>
          <span class="text-nowrap">Achievement Rate</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-3 pr-0">
    <div class="card card-stats mt-2 mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Rural Demos</h5>
            <span class="h4 font-weight-bold mb-0">Goal: 9</span><br>
            <span class="h4 font-weight-bold mb-0">Demolished: 13</span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-success text-white rounded-circle shadow">
              <i class="fas fa-chart-bar"></i>
            </div>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 145%</span>
          <span class="text-nowrap">Achievement Rate</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-3 pr-0">
    <div class="card card-stats mt-2 mb-4 mb-xl-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Mixed-Use Demos</h5>
            <span class="h4 font-weight-bold mb-0">Goal: 7</span><br>
            <span class="h4 font-weight-bold mb-0">Demolished: 8</span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape bg-success text-white rounded-circle shadow">
              <i class="fas fa-chart-bar"></i>
            </div>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm">
          <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> 114%</span>
          <span class="text-nowrap">Achievement Rate</span>
        </p>
      </div>
    </div>
  </div>
</div> -->
<!-- END STATS SECTION -->

    <div class="row">
      <!-- Letter from the Director -->
      <div class="col-md-8">
        <div class="card shadow">
          <div class="card-header bg-transparent">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Letter from the Director</h6>
                <h2 class="mb-0">Gina Paradis</h2>
              </div>
            </div>
          </div>
          <div class="card-body">

            <img src="/static/misc/trees.jpg" class="w-50 float-right m-1" />

            <p>OK, so let’s address the elephant in the room… yes, the COVID-19 pandemic. Everyone is tired of hearing about it, but it hangs over all of us like the moss in a cypress forest; like stalagmites in a glacial cave…inevitable, menacing, an unescapable reality of the year past and the path forward.</p>

            <p>Let’s face it, much of the last year was spent “coping”. Individuals and families struggled with fear, frustration, loneliness, insecurity, and worst of all, loss.  It was no less challenging for our Land Bank and our partners and stakeholders, especially with work shut-downs and outbreaks, and overwhelming economic uncertainty.  We were most mindful, however, of the devastating impacts that the pandemic had on families, especially the lower wage earners who lost their jobs, couldn’t pay their rent or mortgages, struggled to feed and/or educate their children. We also understood what the pandemic would inevitably portend for housing and commercial real estate issues in our community, and so we spent much of the year planning, and listening, and learning.</p>

            <p>It is true that crisis can define us. It will test our mettle, our perseverance and resiliency. However, it can also provide clarity and purpose. By challenging our perspective, we dig a little deeper to define our priorities, we think more creatively to identify ways in which we might expand our reach and enhance our outcomes.</p>

            <p>This past year that land bank has had to meet the economic and program delivery challenges with creativity and commitment. In an ever-changing environment, we worked with our partners to push our program objectives forward, and create new initiatives to assist hardest-hit neighborhoods and make our programs more accessible and equitable. To this end, we created two new programs:</p>

            <em>
              <img src="/static/misc/handson.jpg" class="w-50 float-right m-1" />
              <p><strong>Hands On Neighborhoods</strong> will eventually be a county-wide initiative that will begin with a PILOT project in Jamestown, NY. There are three main program components to Hands On Neighborhoods:</p>

              <ol>
                <li>Neighborhood Clean Up Events</li>
                <li>Crime Prevention & Neighborhood Safety Enhancement</li>
                <li>Property Maintenance Mentoring & Mobile Tool Library</li>
              </ol>

              <p>The objective of the Hands On Neighborhoods program is to empower community members to work together to create safer and healthier neighborhoods and improve the quality of life for all residents. We will be collaborating with residents, church and civic groups, businesses, municipal leaders, and public safety officials to breathe life into these programs and sustain them over time. As we develop each of the components of this program, we will also be creating a “tool box” so that grass roots efforts can be supported with materials, planning tools and marketing templates, enabling them to execute their own hyper-local events.</p>

              <img src="/static/misc/keys.jpg" class="w-50 float-right m-1" />
              <p>A <strong>Rehab Loan Fund</strong> is in the final stages of development, which will expand access to the CCLBC Sales 4 Rehab program to those of more limited financial means. The program will allow those who meet income and program criteria to secure mortgage loans that include funding for renovations of Land Bank properties. Most Land Bank properties do not qualify for conventional mortgages. Two homes, one in Dunkirk and one in Jamestown will be partially renovated and offered for sale through this program. This initiative is specifically intended to create equitable lending practices in neighborhoods and populations that have been underserved in the past, and will be marketed broadly across our diverse communities.</p>
            </em>

            <p>These two programs will initially focus on those census tracts that represent lower income and racially diverse populations, and those neighborhoods that are most at-risk for disinvestment. It is our hope that these programs will reflect a positive investment in areas that have been previously underserved, and result in more engagement of neighborhood residents in community development across the county. In an effort to expand the accessibility of our programs, the Land Bank will be engaging translation services to ensure our program materials are available in Spanish as well as English, and we will continue to accommodate Spanish speakers with one-on-one translation when necessary.</p>

            <p>As we look forward to a return to some sense of normalcy later in 2021, the Chautauqua County Land Bank stands ready to collaborate with all of our public and private partners to address issues of vacancy, foreclosure crises, and equitable housing opportunity. We continue to identify opportunities to positively impact the quality of housing and residential life of our communities across Chautauqua County.</p>

            <p>We wish to thank our partners, including Enterprise Community Partners, Inc., the New York State Attorney General’s office, the Chautauqua County Legislature, our local municipal leaders, county and local housing champions, and all of those individuals who have supported our efforts through their purchases, services, partnerships, and voices. Sharing our mission, expanding our reach, engaging more stakeholders as we tackle the housing and distressed property in Chautauqua County will continue to be our focus in 2021. We hope you will join us in helping to make our neighborhoods safe and strong, and providing healthy housing opportunities for all!</p>

            <p>Humbly yours,</p>
            <p class="lead">Gina Paradis</p>

          </div>
        </div>
      </div><!-- /.col-md-8 -->
      <div class="col-md-4">
        <div class="card shadow">
          <div class="card-body">
            <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/cclbc-annual-report-2018.appspot.com/o/images%2FGina%20Color%201.jpg?alt=media&token=808a8a64-c935-4c2a-b65b-1dd1b4c657a2">
          </div><!-- /.card-body -->
        </div><!-- /.card .shadow -->

    </div>
    </div><!-- /.row -->
  </div><!-- /#Introduction -->
</template>

<script>
export default {
  data () {
    return {
      beforeImage: '/static/before_after/before.jpg',
      afterImage: '/static/before_after/after.jpg'
    }
  }
}
</script>
